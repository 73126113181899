section {
	@include clear;
	position: relative;
	padding: 120px 0;

	&.full {
		background-position: center;
		background-size: cover;
	}
	&.full-img { 
		background-position: center;
		background-size: cover;
		min-height: 350px; 
	}
	&.full-divider {
		background-position: center;
		background-size: cover;
		padding: 12% 0;
	}
}