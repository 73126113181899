footer {
	@include clear;
	position: relative;
	padding: 60px 0;
	color: $white;
	font-size: 24px;
	line-height: 36px;
	background: $mainC;

	@media (max-width: $md){
		font-size: 18px;
		line-height: 24px;
	}

	a {
		cursor: pointer;

		&:hover {
			color: $black;
		}
	}
}