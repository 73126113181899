.form {

	&-kontakt {
		margin-top: 40px;

		&-item {
			display: block;
			font: {
				family: 'OpenSans', sans-serif;
				size: 14px;
			}
			line-height: 24px;
			width: 100%;
			box-sizing: border-box;
			padding: 10px;
			border: 1px solid darken($white, 12%);
			resize: none;

			&[type="submit"], &[type="reset"]{
				border: 0;
				cursor: pointer;
				background-color: $mainC;
				color: $white;
			}
			& + & {
				margin-top: 10px;
			}
		}
	}
}