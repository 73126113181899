//--------------------> Breakpoints
$xs:	540px;
$sm:	768px;
$md:	992px;
$lg:	1200px;

//--------------------> Colors
$mainC: 		#e72541;
$black:			#202020;
$white:			#F8F8F8;

$gray: 			#cccccc;
$braun: 		#683c11;