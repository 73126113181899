// Variables
$g_ma:	1080px;	// Grid max
$g_co:	12;		// Cols
$g_gu:	40px;	// Gutter
$q_xs:	540px;	// Extra small devices
$q_sm:	768px;	// Small devices
$q_md:	992px;	// Medium devices
$q_lg:	1200px;	// Large devices

// container
.con {
	@include clear;
	position: relative;
	max-width: $g_ma;
	padding-left: $g_gu;
	padding-right: $g_gu;
	margin: 0 auto;

	& + & {
		margin-top: $g_gu*3;
	}
}
// row
.row {
	@include clear;
	margin-left: $g_gu / -2;
	margin-right: $g_gu / -2;
	
	& + & { margin-top: $g_gu*2; }
}
// col base
.col {
	position: relative;
	min-height: 1px;
	box-sizing: border-box;
	padding-left: $g_gu/2;
	padding-right: $g_gu/2;
}
// Extra small devices
@media (max-width: ($q_xs)-1) {
	[class*='xs-'] { float: left; }
	@for $i from 1 to $g_co+1 {
		.xs-#{$i} { width: (100%/$g_co)*$i; }
		.xs-o-#{$i} { margin-left: (100%/$g_co)*$i; }
	}
	.xs-#{$g_co/2}:nth-of-type(1n+#{$g_co/4}) { margin-top: $g_gu; }
	.xs-#{$g_co} + .xs-#{$g_co} { margin-top: $g_gu; }
	.xs-0 { display: none; }
}
// Small devices
@media (min-width: $q_xs) and (max-width: ($q_sm)-1) {
	[class*='sm-'] { float: left; }
	@for $i from 1 to $g_co+1 {
		.sm-#{$i} { width: (100%/$g_co)*$i; }
		.sm-o-#{$i} { margin-left: (100%/$g_co)*$i; }
	}
	.sm-#{$g_co/2}:nth-of-type(1n+#{$g_co/4}) { margin-top: $g_gu; }
	.sm-#{$g_co} + .sm-#{$g_co} { margin-top: $g_gu; }
	.sm-0 { display: none; }
}
// Medium devices
@media (min-width: $q_sm) and (max-width: ($q_md)-1) {
	[class*='md-'] { float: left; }
	@for $i from 1 to $g_co+1 {
		.md-#{$i} { width: (100%/$g_co)*$i; }
		.md-o-#{$i} { margin-left: (100%/$g_co)*$i; }
	}
	.md-#{$g_co/2}:nth-of-type(1n+#{$g_co/4}) { margin-top: $g_gu; }
	.md-#{$g_co} + .md-#{$g_co} { margin-top: $g_gu; }
	.md-0 { display: none; }
}
// Large devices 
@media (min-width: $q_md) {
	[class*='lg-'] { float: left; }
	@for $i from 1 to $g_co+1 { 
		.lg-#{$i} { width: (100%/$g_co)*$i; } 
		.lg-o-#{$i} { margin-left: (100%/$g_co)*$i; }
	}
	.lg-#{$g_co/2}:nth-of-type(1n+#{$g_co/4}) { margin-top: $g_gu; }
}