nav {
	@include clear;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 100px;
	background: url('img/nav-bg.jpg') no-repeat center $black;
	box-shadow: 0 -2px 8px 5px darken($black, 20%);

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 2;
		width: 100%;
		height: 5px;
		background: color-opacity(darken($black, 20%), .35);
	}
	&.fixed {
		position: fixed;
		height: 50px;
		top: -100px;

		@media (max-width: $md){
			height: 100px;
		}
		@media (max-width: $sm){
			height: 50px;
		}
	}
}
.logo {
	position: relative;
	z-index: 4;
	display: block;
	float: left;
	width: 170px;
	margin-top: 10px;

	& > a {
		display: block;

		& > img {
			display: block;
		}
	}
	.fixed & {
		margin-top: 3px;
		width: 125px;
		height: 40px;
		overflow: hidden;

		&:after, &:before {
			content: none;
		}
	}
	@media (min-width: $sm) and (max-width: $md){
		float: none;
		width: 125px;
		height: 40px;
		overflow: hidden;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
	}
}

.nav {

	&-button {
		position: absolute;
		right: 40px;
		top: 35px;
		width: 50px;
		height: 24px;
		cursor: pointer;
		display: none;

		& > span {
			display: block;
			width: 100%;
			height: 4px;
			margin-bottom: 6px;
			background: $mainC;
			transition: .3s ease;
		}
		&.active > span {
			width: 30px;

			&:nth-of-type(1){ transform: translate(10px, 10px) rotate(45deg); }
			&:nth-of-type(2){ opacity: 0; }
			&:nth-of-type(3){ transform: translate(10px, -10px) rotate(-45deg); }
		}
		.fixed & {
			top: 10px;
		}
		@media (max-width: $sm){ display: block; }
	}
	&-main {
		@include clear;
		display: block;
		float: left;
		margin-left: 40px;
		margin-top: 25px;

		&-item {
			display: block;
			float: left;
			font-family: 'Andada', serif;
			font-weight: 400;
			transition: .3s ease;

			& > a {
				display: block;
				font-size: 25px;
				font-weight: 700;
				line-height: 50px;
				color: $white;
				text-transform: uppercase;
				transition: .3s ease;

				&:hover {
					color: $mainC;
				}
				.fixed & {
					font-size: 20px;
					line-height: 30px;
				}
				@media (max-width: $lg){
					font-size: 20px;
					line-height: 40px;
				}
				@media (max-width: $sm){
					line-height: 30px;
					display: inline-block;
				}
			}
			&.current > a {
				color: $mainC;
			}
			& + & {
				margin-left: 30px;

				@media (max-width: $lg){
					margin-left: 28px;
				}
				@media (max-width: $md){
					margin-left: 20px;
				}
				@media (max-width: $sm){
					margin-left: 0;
					margin-top: 10px;
				}
			}
			@media (max-width: $md){
				display: inline-block;
				float: none;
			}
			@media (max-width: $sm){
				display: block;
			}
		}
		.fixed & {
			margin-top: 15px;

			@media (max-width: $sm){
				margin: 0;
				top: 45px;
			}
		}
		@media (max-width: $md){
			float: none;
			margin-top: 5px;
			margin-left: 0;
			text-align: center;
		}
		@media (max-width: $sm){
			position: fixed;
			top: 95px;
			left: 0;
			width: 100%;
			margin: 0;
			box-sizing: border-box;
			padding: 20px 40px;
			background: $black;
			display: none;
		}
	}
	&-anker {
		position: absolute;
		top: 0;
	}
	&-footer {
		display: block;
		text-align: center;
		padding: 5px 40px;
		background: $black;

		&-item {
			display: inline-block;
			font-size: 12px;
			color: darken($white, 40%);

			& + & {
				margin-left: 100px;

				@media (max-width: $xs){
					margin-left: 30px;
				}
			}
			&:hover > a {
				color: $mainC;
				transition: .3s ease;
			}
		}
		@media (max-width: $xs){
			padding: 5px 20px;
		}
	}
}