/*	FONT-FACE SUPPORT
=========================================================================================================
									Chrome		Explorer	Firefox		Opera		Safari
	Embedded Open Type (eot)		-			4+			-			-			-
	Web Open Font Format (woff)		5+			9+			3.6+		10+			5.1+
	True Type (ttf)					4+			-			3.5+		10+			3.1+
	Open Type (otf)					4+			-			3.5+		10+			3.1+

	FONT-WEIGHT
=========================================================================================================
	100	Extra-Light			200	Lighter				300 Light
	400	Regular				500 Medium				600	Semi Bold
	700	Bold				800	Bolder				900	Extra Bold

	BULLETPROOF @font-face: SMILEY VARIATION
========================================================================================================= */


// Adanda
@font-face {
	font-family: 'Andada';
	font-weight: 400;	// Regular
	font-style: normal;
	src: local('☺'),
	url('fonts/andada-regular.woff') format('woff');
}
@font-face {
	font-family: 'Andada';
	font-weight: 700;	// Bold
	font-style: normal;
	src: local('☺'),
	url('fonts/andada-bold.woff') format('woff');
}

// Eraser
@font-face {
	font-family: 'Eraser';
	font-weight: 400;	// Regular
	font-style: normal;
	src: local('☺'),
	url('fonts/EraserRegular.woff') format('woff');
}

// Open Sans
@font-face {
	font-family: 'OpenSans';
	font-weight: 300;	// Light
	font-style: normal;
	src: local('☺'),
	url('fonts/OpenSans-Light.woff') format('woff');
}
@font-face {
	font-family: 'OpenSans';
	font-weight: 400;	// Regular
	font-style: normal;
	src: local('☺'),
	url('fonts/OpenSans-Regular.woff') format('woff');
}
@font-face {
	font-family: 'OpenSans';
	font-weight: 600;	// Semibold
	font-style: normal;
	src: local('☺'),
	url('fonts/OpenSans-Semibold.woff') format('woff');
}