//--------------------> Links
.link {
	color: $mainC;
	cursor: pointer;

	&-dc {
		display: inline-block;
		font-weight: 600;
		line-height: 30px;
		color: $white;
		padding: 5px 20px;
		background: $mainC;
		transition: .3s ease;

		&:hover {
			color: $black;
		}
	}
}
//--------------------> Text
.regular	{ font-weight: 400; }
.semi		{ font-weight: 600; }
.bold, b	{ font-weight: 700; }

.align {

	&-right { text-align: right; }
	&-left { text-align: left; }
	&-center { text-align: center; }
	&-no-sm { 
		@media (max-width: $sm) {
			text-align: inherit;
		}
	}
}
.small { 
	font-size: 12px; 
	font-weight: 700; 
}
.bigger {
	font-size: 24px;
	line-height: 30px;
}
.big {
	font-family: 'Andada', serif;
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;
}
//--------------------> list
.list {
	list-style: square inside;
	margin: 20px 0;

	&-item {
		margin-bottom: 10px;
	}
}
//--------------------> text color
.tc {

	&-white { color: $white; }
}
//--------------------> Images
.img {

	&-center {
		display: block;
		margin: 0 auto;
	}
}
//--------------------> Icons
.ic {

	&-mail {
		padding-left: 30px;
		background: svg-url('<svg width="20px" height="20px" viewBox="0 0 483.3 483.3"><path fill="'+$white+'" d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1 v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5 c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"></path><path fill="'+$white+'" d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3 c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95 c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4 c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"></path></svg>') no-repeat left center;
		transition: .3s ease;
		
		&:hover {
			color: $black;
			background: svg-url('<svg width="20px" height="20px" viewBox="0 0 483.3 483.3"><path fill="'+$black+'" d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1 v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5 c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"></path><path fill="'+$black+'" d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3 c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95 c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4 c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"></path></svg>') no-repeat left center;
		}
	}
	&-fb {
		padding-left: 30px;
		background: svg-url('<svg width="20px" height="20px" viewBox="0 0 60.734 60.733"><path fill="'+$white+'" d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914 v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462 v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"></path></svg>') no-repeat left center;
		transition: .3s ease;

		&:hover {
			color: $black;
			background: svg-url('<svg width="20px" height="20px" viewBox="0 0 60.734 60.733"><path fill="'+$black+'" d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914 v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462 v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"></path></svg>') no-repeat left center;
		}
	}
}