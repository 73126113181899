.hero {
	@include clear;
	position: relative;
	height: 100%;
	overflow: hidden;

	&-slide {
		@include clear;
		position: relative;
		list-style: none;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;

		&-item {
			height: 100%;
			width: 100%;
			overflow: hidden;
			background-position: center;
			background-size: cover;

			& > img {
				height: 100%;
				max-width: none;
			}
			&-caption {
				position: absolute;
				left: 10%;
				bottom: 20%;
				z-index: 2;
				font: {
					family: 'Andada', sans-serif;
					size: 80px;
					weight: 600;
				}
				line-height: 100px;
				color: $white;
				text-shadow: 0 0 4px $black;
				padding-right: 100px;

				@media (max-width: $sm){
					left: 5%;
					bottom: 10%;
					width: 90%;
					padding-right: 5%;
					font-size: 60px;
					line-height: 80px;
				}
				@media (max-width: $xs){
					font-size: 40px;
					line-height: 60px;
				}
			}
		}
		&_nav {
			position: absolute;
			z-index: 10;
			top: 50%;
			width: 40px;
			height: 80px;
			overflow: hidden;
			background: svg-url('<svg viewBox="0 0 40 40"><path fill="'+$braun+'" d="M7.92,28.86c2.47-2.23,4.94-4.45,7.4-6.69a1,1,0,0,1,1.58.05c1.75,1.82,1.84,1.41.12,3.35C13.11,30,9.11,34.25,4.91,38.34c-.23.23-.46.46-.71.67a2.44,2.44,0,0,1-3.31.08A2.39,2.39,0,0,1,1,35.73C2.21,34.47,3.45,33.26,4.67,32l3.21-3.21Zm2.7.55a.6.6,0,0,0-.87,0,.57.57,0,0,0-.08.86.58.58,0,0,0,.91,0A.59.59,0,0,0,10.61,29.41Zm4-4.06a.58.58,0,0,0-.84,0,.55.55,0,0,0,0,.85.57.57,0,0,0,.91,0A.6.6,0,0,0,14.61,25.35Z"/><path fill="'+$gray+'" d="M18.36,23.15l-1.53-1.53c.57-.46,4.79-4.63,5.37-5A1.65,1.65,0,0,0,23.12,15a3.09,3.09,0,0,1,1.17-2.59C29.48,7.34,30.91,5.92,36.06.83A3.36,3.36,0,0,1,36.69.3a41.15,41.15,0,0,1-4,5c-3.83,3.82-3.91,4-7.79,7.75a2.41,2.41,0,0,0-.68,2.67,2.38,2.38,0,0,0,2.67-.68c3.19-3.31,2.8-2.8,6-6.09a39.81,39.81,0,0,1,6.8-5.64L39,4.1C33.83,9.29,32.4,10.79,27.29,16a2.21,2.21,0,0,1-1.82.84,2.22,2.22,0,0,0-2.33,1.22C22.88,18.56,18.75,22.65,18.36,23.15Z"/><path fill="'+$gray+'" d="M19,17c7.21,7.19,14.46,14.13,21,22a11.3,11.3,0,0,1-5.75-.77,30.41,30.41,0,0,1-8.65-5.62,104.16,104.16,0,0,1-9.45-9.22c-.22-.24-.41-.49-.68-.82A6.41,6.41,0,0,0,17,19.23C17.26,18.32,18.35,17.88,19,17Z"/><path fill="'+$braun+'" d="M15.4,20.17c-.81-.82-1.8-1.27-1.79-2.56a2.49,2.49,0,0,0-1.21-1.92,51,51,0,0,1-6.34-5.55,32.91,32.91,0,0,1-2.47-2.9,2.06,2.06,0,0,0-2.27-1,.56.56,0,0,1-.69-.29A4.08,4.08,0,0,1,.24,2.43a2.13,2.13,0,0,1,3-1.26A5.11,5.11,0,0,1,4.86,2.33L15.72,13.09c1,1,1.95,1.93,2.94,2.88.29.28.38.55.1.84C17.67,17.91,16.56,19,15.4,20.17ZM4.11,3.89a.65.65,0,0,0,0-.92.62.62,0,0,0-1,0,.61.61,0,0,0,0,.93A.67.67,0,0,0,4.11,3.89ZM15.18,16a.67.67,0,0,0,.94.06.68.68,0,1,0-.86-1A.68.68,0,0,0,15.18,16Zm-5.06-6a.62.62,0,0,0,0-.94A.6.6,0,0,0,9.22,9a.64.64,0,0,0-.05,1A.69.69,0,0,0,10.12,10Z"/></svg>') no-repeat color-opacity($black, .8);
			background-size: 80px;
			padding: 10px;
			border-radius: 20px;
			opacity: .75;
			transition: .3s ease;

			&:hover {
				opacity: 1;
			}
			&.prev {
				left: -10px;
				background-position: right 10px center;
			}
			&.next {
				right: -10px;
				background-position: 10px center;
			}
		}
	}
}