.teaser {
	position: relative;
	z-index: 2;
	background: url('img/teaser-bg.jpg') no-repeat center $black;
	background-size: cover;
	padding: 60px 0;

	&-title {
		font-family: 'Eraser', sans-serif;
		font-size: 60px;
		line-height: 80px;
		text-align: center;
		color: $mainC;

		@media (max-width: $sm){
			font-size: 45px;
			line-height: 60px;
		}
		@media (max-width: $xs){
			font-size: 35px;
			line-height: 45px;
		}
	}
	&-sub {
		font-family: 'Eraser', serif;
		font-size: 40px;
		line-height: 60px;
		color: $white;
		text-align: center;
		margin-top: 40px;

		@media (max-width: $sm){
			font-size: 30px;
			line-height: 50px;
		}
		@media (max-width: $xs){
			font-size: 25px;
			line-height: 40px;
		}
	}
	&-img {
		position: relative;
		display: block;
		
		& > img {
			display: block;
		}
	}
	&-box {
		padding: 20px 30px;
		background: color-opacity($white, .85);
	}
}
.discount {
	position: fixed;
	right: -150px;
	top: 50%;
	z-index: 5;
	width: 100px;
	padding: 5px;
	padding-bottom: 15px;
	background: $mainC;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	overflow: hidden;
	cursor: pointer;
	transition: .3s ease;

	&.active {
		right: 0;
	}
	& > img {
		display: block;
		border-radius: 20px;
		border-bottom-right-radius: 0;
		margin-bottom: 10px;

		@media (max-width: $xs){
			border-radius: 10px;
			border-bottom-right-radius: 0;
		}
	}
	& > span {
		display: block;
		font-size: 15px;
		font-weight: 600;
		line-height: 18px;
		color: $white;
		padding-left: 5px;
		text-transform: uppercase;

		@media (max-width: $md){
			font-size: 12px;
			line-height: 16px;
			padding-left: 0;
		}
	}
	@media (max-width: $md){
		width: 75px;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		padding-bottom: 5px;
	}
	@media (max-width: $sm){
		display: none;
	}
}