//--------------------> basics
html, body {
	position: relative;
	height: 100%;
}
body {
	font: {
		family: 'OpenSans', sans-serif;
		size: 18px;
		weight: 300;
	}
	line-height: 28px;
	color: $black;
	background: $white;
}
a {
	text-decoration: none;
	color: inherit;
}
img {
	max-width: 100%;
}
//--------------------> text
h1, h2.title {
	font-family: 'Andada', sans-serif;
	font-size: 80px;
	line-height: 95px;
	font-weight: 700;

	&:not(:only-child){
		margin-bottom: 40px;
	}
	& > span {
		display: block;
		font-size: 60%;
		line-height: 70%;
	}
	@media (max-width: $lg){
		font-size: 70px;
		line-height: 85px;
	}
	@media (max-width: $md){
		font-size: 60px;
		line-height: 75px;
	}
	@media (max-width: $sm){
		font-size: 40px;
		line-height: 55px;
	}
}

h3 {
	font: {
		family: 'Andada', sans-serif;
		size: 30px;
		weight: 700;
	}
	line-height: 40px;
	margin-bottom: 20px;

	p + & {
		margin-top: 50px;
	}
	&.title {
		font-family: 'Eraser', sans-serif;
		color: $mainC;
	}
}
p + p {
	margin-top: 20px;
}